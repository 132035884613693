import _ProductCard, {
  type ProductCardProps,
} from '@libs-components/features/page-components/components/picked-product-list/products/product-card'
import { productList as extraProductList } from '../extra/product-list'

const ProductCard = ({ onClick, ...props }: ProductCardProps) => {
  const _onClick: ProductCardProps['onClick'] = product => {
    extraProductList.onProductCardClick(product)
    if (typeof onClick === 'function') {
      onClick(product)
    }
  }

  return <_ProductCard {...props} onClick={_onClick} />
}

export default ProductCard
