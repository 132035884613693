import type { ProductType } from '../../../../../../../types'
import { paletteColors } from '@wordup/assets/colors'
import { useTranslation } from 'next-i18next'
import { Icon } from '../../../../../../../components/icon'
import { Text } from '../../../../../../../components/text'

const Countdown = ({
  attributes: { countdown },
  isHorizontal,
}: {
  attributes: ProductType.Data['attributes']
  isHorizontal?: boolean
}) => {
  const { t } = useTranslation()
  if (countdown?.style !== 'crowdfunding') return null

  const KPI = (() => {
    const percentage = Math.floor((countdown.currentCount / countdown.totalCount) * 100)
    if (!Number.isFinite(percentage) || percentage < 100) return ''
    return `${percentage}%`
  })()

  return (
    <div data-testid='product-card-countdown' className='flex flex-wrap items-center gap-1'>
      <Icon type='local_fire_department' style={{ color: paletteColors['peach-500'] }} />
      <Text variant={isHorizontal ? 'label_s_400' : 'body_m_400'} color='peach-500'>
        {t('crowdin:product-card.crowdfunding')}
      </Text>
      {KPI && (
        <Text variant={isHorizontal ? 'label_s_400' : 'body_m_400'} color='peach-500'>
          {KPI}
        </Text>
      )}
    </div>
  )
}

export default Countdown
